<template>
    <div class="pt-5">
        <div class="row font-bold title justify-center">
            Phần mềm quản lý bến xe toàn quốc
        </div>
        <div class="row justify-center pt-4">
            <SearchVue :width="'50%'" @search="search" />
        </div>
        <div class="row mt-5 pt-5">
            <div class="content">
                <div
                    class="frame-items"
                    v-for="(data, index) in dataSource"
                    :key="index"
                >
                    <div class="sub-item-name row align-center mb-2">
                        {{ data.subName }}
                    </div>
                    <!-- <div class="items">
                        
                    </div>-->
                    <div class="row justify-space-between items">
                        <div
                            v-for="(item, i) in data.items"
                            :key="i"
                            @click="$router.push(item.to)"
                        >
                            <div class="item" v-if="item.isQuyen">
                                <div
                                    class="icon row justify-center align-center"
                                >
                                    <i
                                        :class="item.icon"
                                        :style="'color:' + getRandomColor()"
                                    ></i>
                                </div>
                                <div class="name row justify-center mt-2">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SearchVue from "@/components/_Common/Components/Search.vue";

export default {
    layout: "application",
    // IsFreePage: true,
    components: {
        SearchVue,
    },
    data() {
        return {
            dataSource: [
                // {
                //     id: 1,
                //     subName: "Quản lý thông tin",
                //     show: true,
                //     items: [
                //         {
                //             name: "Thông tin lái xe",
                //             icon: "mdi mdi-checkerboard",
                //             to: "/Quan-Ly-Thong-Tin/Thong-Tin-Lai-Xe/",
                //         }
                //     ]
                // },
                {
                    id: 2,
                    subName: "Báo cáo",
                    show: false,
                    items: [
                        {
                            name: "Báo cáo hoạt động của xe",
                            icon: "mdi mdi-checkerboard",
                            to: "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Xe",
                            isQuyen: this.$Helper.Common.CheckRole(
                                this.$i18n.t("Quyen.BaoCaoBenXe"),
                                this.$i18n.t(
                                    "Quyen.DanhSachBaoCaoHoatDongCuaXe"
                                )
                            ),
                        },
                        {
                            name: "Tổng hợp của xe theo tháng",
                            icon: "mdi mdi-checkerboard",
                            to: "/Bao-Cao-Thong-Ke/Tong-Hop-Cua-Xe-Theo-Thang",
                            isQuyen: this.$Helper.Common.CheckRole(
                                this.$i18n.t("Quyen.BaoCaoBenXe"),
                                this.$i18n.t(
                                    "Quyen.DanhSachBaoCaoHoatDongCuaXeTheoThang"
                                )
                            ),
                        },
                        {
                            name: "Nhật ký truyền tải",
                            icon: "mdi mdi-checkerboard",
                            to: "/Bao-Cao-Thong-Ke/Nhat-Ky-Truyen-Tai",
                            isQuyen: this.$Helper.Common.CheckRole(
                                this.$i18n.t("Quyen.BaoCaoBenXe"),
                                this.$i18n.t(
                                    "Quyen.DanhSachBaoCaoNhatKyTruyenTai"
                                )
                            ),
                        },
                        {
                            name: "Báo cáo hoạt động của bến xe",
                            icon: "mdi mdi-checkerboard",
                            to: "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Ben-Xe",
                            isQuyen: this.$Helper.Common.CheckRole(
                                this.$i18n.t("Quyen.BaoCaoBenXe"),
                                this.$i18n.t(
                                    "Quyen.DanhSachBaoCaoHoatDongCuaBenXe"
                                )
                            ),
                        },
                        {
                            name: "Trạng thái truyền tải dữ liệu của bến xe",
                            icon: "mdi mdi-checkerboard",
                            to: "/Bao-Cao-Thong-Ke/Trang-Thai-Truyen-Tai-Du-Lieu-Cua-Ben-Xe",
                            isQuyen: this.$Helper.Common.CheckRole(
                                this.$i18n.t("Quyen.BaoCaoBenXe"),
                                this.$i18n.t(
                                    "Quyen.DanhSachBaoCaoTrangThaiTruyenTaiDuLieuCuaBenXe"
                                )
                            ),
                        },
                        {
                            name: "Báo cáo lỗi dữ liệu truyền tải",
                            icon: "mdi mdi-checkerboard",
                            to: "/Bao-Cao-Thong-Ke/Bao-Cao-Vi-Pham-Truyen-Tai-Du-Lieu",
                            isQuyen: this.$Helper.Common.CheckRole(
                                this.$i18n.t("Quyen.BaoCaoBenXe"),
                                this.$i18n.t(
                                    "Quyen.DanhSachBaoCaoLoiDuLieuTruyenTai"
                                )
                            ),
                        },
                    ],
                },
                {
                    id: 3,
                    subName: "Biểu đồ",
                    show: false,
                    items: [
                        {
                            name: "Biểu đồ nhật ký truyền tải",
                            icon: "mdi mdi-chart-line",
                            to: "/Bao-Cao-Thong-Ke/Nhat-Ky-Truyen-Tai/Bieu-Do",
                            isQuyen: this.$Helper.Common.CheckRole(
                                this.$i18n.t("Quyen.BaoCaoBenXe"),
                                this.$i18n.t(
                                    "Quyen.BieuDoBaoCaoNhatKyTruyenTai"
                                )
                            ),
                        },
                        {
                            name: "Biểu đồ hoạt động của bến xe",
                            icon: "mdi mdi-chart-bar-stacked",
                            to: "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Ben-Xe/Bieu-Do",
                            isQuyen: this.$Helper.Common.CheckRole(
                                this.$i18n.t("Quyen.BaoCaoBenXe"),
                                this.$i18n.t(
                                    "Quyen.BieuDoBaoCaoHoatDongCuaBenXe"
                                )
                            ),
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        search(data) {
            console.log("search -> data", data);
        },
        getRandomColor() {
            var arr = [
                "red",
                "blue",
                "SteelBlue",
                "green",
                "orange",
                "SeaGreen",
                "SlateBlue",
                "Aquamarine",
                "BlueViolet",
                "Brown",
                "BurlyWood",
                "CadetBlue",
                "Chocolate",
                "Coral",
                "CornflowerBlue",
                "Crimson",
                "DarkGoldenRod",
                "GreenYellow",
                "DarkOrchid",
                "ForestGreen",
                "GoldenRod",
                "Indigo",
                "LawnGreen",
                "LightCoral",
                "LightSeaGreen",
                "Lime",
                "LimeGreen",
                "MediumPurple",
                "MediumSlateBlue",
                "OliveDrab",
                "OrangeRed",
                "Peru",
                "Salmon",
                "Sienna",
                "Maroon",
            ];
            var color = "";
            color = arr[Math.floor(Math.random() * arr.length)];
            return color;
        },
    },
    created() {},
};
</script>

<style scoped>
.title {
    font-size: 36px;
}
.content {
    width: 800px;
    margin: 0 auto;
}
.frame-items {
    width: 100%;
    float: left;
    position: relative;
}
.frame-items::after {
    position: absolute;
    content: "";
    bottom: 10px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #ccc;
}
.frame-items .icon {
    width: 40px;
    height: 40px;
    background: #f0f0f0;
    border-radius: 50px;
    margin: 0 auto;
}
.items {
    width: 100%;
    display: block;
    float: left;
}
.frame-items .item {
    float: left;
    padding: 8px;
    border-radius: 8px;
    width: calc(20% - ((24px + 24px + 24px + 48px) * 0.2));
    margin-bottom: 24px;
    margin-right: 24px;
    transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
}
.frame-items .item:nth-child(5n) {
    margin-right: 0;
}
.frame-items .item:hover {
    background: #e5e5e5;
    /* box-shadow: 0px 4px 10px 1px #ccc; */
    transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    cursor: pointer;
}
.frame-items .item .name {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}
.sub-item-name {
    font-size: 20px;
    font-weight: 500;
    /* cursor: pointer; */
}
</style>